
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import stores from '@/stores';
import Axios from 'axios';
import { mixin as Clickaway } from 'vue-clickaway';
import NevronSearchNoResults from '@/components/NevronSearchNoResults.vue';
import _ from 'lodash';
import { modulesMetaData } from '@/helpers/constants';
import moment from 'moment';
import NevronClickConfirm from '@/components/NevronClickConfirm.vue';
import {bus, manageTranslate, translate, translatedDisplayName} from '@/helpers/functions';
// @ts-ignore
import Switches from 'vue-switches';
import {showToaster} from '@/components/DashboardComposer/helpers';


@Component({
  methods: {manageTranslate, translate},
  components: {
    NevronSearchNoResults,
    NevronClickConfirm,
    Switches,
  },
  mixins: [Clickaway],
})
export default class NevronTableGeneric extends Vue {
  @Prop({ required: true })
  instance!: IModule;

  @Prop({ required: true })
  items!: IItem[] | IItem;

  @Prop({ default: false })
  isPageItem!: boolean;

  @Prop({ default: null })
  unlinkMessage!: string;

  @Prop({default: true})
  editActiveFlag!: boolean;

  @Prop({ default: false })
  isItemCategory!: boolean;

  @Prop({ default: null })
  metaKey!: string | null;

  // show active switch
  @Prop({ default: true })
  hasActive!: boolean;

  // allow toggling active
  @Prop({ default: false })
  toggableActive!: boolean;

  // show select checkboxes
  @Prop({ default: false })
  hasSelect!: boolean;

  // emit item when clicked, instead of opening it
  @Prop({ default: false })
  emitOpenItem!: boolean;

  // select item when clicked, instead of opening it
  @Prop({ default: false })
  selectOpenItem!: boolean;

  // when this value is changed, selected is cleared
  @Prop({ default: false })
  resetSelected!: boolean;

  // ACTIONS
  @Prop({ default: true })
  hasActions!: boolean;
  @Prop({ default: true })
  hasEdit!: boolean;
  @Prop({ default: false })
  hasUnlink!: boolean;
  @Prop({ default: false })
  hasRemove!: boolean;
  @Prop({ default: true })
  firstColClickable!: boolean;

  @Prop({ default: false })
  hasDuplicate!: boolean;

  @Prop({ default: false })
  checkPopup!: boolean;

  @Prop({ default: () => [] })
  defaultSelectedArray!: IItem[];

  selectedArray: IItem[] = [];
  selectedItem: IItem | null = null;
  allChecked = false;
  count: number = 0;
  dropdownSelectedClass: string = 'bg-white';
  dropdownOpen: any=[];

  get instanceMeta(): any {
    // @ts-ignore
    return modulesMetaData[this.instance.routeName];
  }

  get tableMeta(): any {
    if (this.metaKey && this.instanceMeta[this.metaKey]) {
      return this.instanceMeta[this.metaKey];
    }
    return this.instanceMeta;
  }

  get fields(): any {
    if (!this.tableMeta.fields) {
      return this.instanceMeta.fields;
    }
    return this.tableMeta.fields;
  }

  isSurveyEditable(item:any){
    if(this.instance.routeName !== 'surveys'){
      return this.hasEdit;
    }

    if(item?.status === 'SCHEDULED' || item?.status === 'DRAFT'){
      return this.hasEdit;
    }

    return false;
  }

  manageObjectFields(object: IItem | IItem[], subField: string ,notTranslatable: boolean): string {
    let str = '';
    if (Array.isArray(object)) {
      for (const item of object) {
        if (str !== '') {
          str += ', ';
        }
        if (item[subField]) {
          str += notTranslatable ? item[subField] : Vue.prototype.translate(item[subField]);
        } else {
          str += notTranslatable ? item : Vue.prototype.translate(item);
        }
      }
    } else {
      str = notTranslatable ? object[subField] : Vue.prototype.translate(object[subField]);
    }
    return str;
  }

  get localItems() {
    if (Array.isArray(this.items)) {
      return this.items;
    } else if (this.items) {
      return [this.items];
    }
    return [];
  }

  get hasActiveSwitch() {
    if (this.instanceMeta.noActive || this.tableMeta.noActive) {
      return false;
    }
    return this.hasActive;
  }
@Watch('dropdownOpen')
  getClassesOfSelectedStatus(field: any, status: string,index:number) {
    let classes = '';
    if (field?.options?.length > 0) {
      const foundOption = field.options.find((option: any) => option.value === status.toLowerCase());
      if (foundOption) {
        classes = foundOption.classes;
      }
    }
    return classes;
  }

  handleSelectChange(field:any,item: any){
    if(field.showConfirmModal){
      this.$emit('showConfirmModal', item);
    }
    this.$emit('saveStatus', item);
  }

  shouldOpen(fieldIndex: number) {
    const hasImage = this.fields[0].type === 'image';
    return (!hasImage && fieldIndex === 0) || (hasImage && fieldIndex === 1);
  }


  showTypes(item:object,subfield:object){
    // @ts-ignore
    return this.instance.showTypes(item,subfield)
  }

  editItem(item: IItem, force = false) {
    if(!this.isSurveyEditable(item)){
      showToaster('danger','Not Editable','The item is not editable','422');
      return;
    }

    if (!force && this.selectOpenItem) {
      this.selectItem(item);
      return;
    }
    if (this.emitOpenItem) {
      this.$emit('openItem', item, this.instance);
      return;
    } else {
      const showPath = (this.instanceMeta.dynamicModule) ? 'items.show' : 'show';
      this.$router.push({
        name: `${this.instance.routeName}.${showPath}`,
        params: { id: String(item.id) },
      });
    }
    this.$emit('openItem', item, this.instance);

  }

  openViewList(item: IItem) {
    localStorage.setItem('viewList', 'viewList');
    // @ts-ignore
    this.openItem(item, 'viewList');
  }
  // item will open when we click on the name
  openItem(item: IItem, type = '') {
    if(this.hasEdit && !this.isSurveyEditable(item)){
      showToaster('danger','Not Editable','The item is not editable','422');
      return;
    }
    if (type !== 'viewList') {
      localStorage.removeItem('viewList');
    }
    // this condition will work for pages item's link module redirection to index page
    if (this.isPageItem) {
      this.$router.push({
        name: `catalogue.items`,
        params: { moduleId: String(item.id) },
      });
    } else if (this.emitOpenItem) {
      // this condition will work for anything edit on popup model
      this.$emit('openItem', item, this.instance);
      return;
    } else if (this.selectOpenItem) {
      // this condition will work for anything select specific element into the popup list
      this.selectItem(item);
      return;
    } else {
      // this condition will work for anything we want to open
      const showPath = (this.instanceMeta.dynamicModule) ? 'items.show' : 'show';
      this.$router.push({
        name: `${this.instance.routeName}.${showPath}`,
        params: { id: String(item.id) },
      });
    }
  }

  // item will be selected on popup when we click on the name
  selectItem(item: IItem) {
    if (this.tableMeta.selectOne) {
      this.selectedItem = item;
    } else {
      if (this.selectedArray.filter((el: IItem) => el.id === item.id).length === 0) {
        this.selectedArray.push(item);
      } else {
        this.selectedArray = this.selectedArray.filter((it: IItem) => it.id !== item.id);
      }
    }
    this.updateCheck();
  }

  // item will toggle when we are on index page
  toggleActive(item: any) {
    if (this.editActiveFlag) {
      if (this.isItemCategory) {
        item.active = Number(!item.active);
        // @ts-ignore
        this.instance.updateCategory(item.id, item)
          .then((response) => {
            if (!response.success) {
              throw new Error(response.error.message);
            }
            showToaster('success', translatedDisplayName(item), translate('successfully-updated'));
          })
          .catch((error: any) => {
            showToaster('danger', '', error);
          });
        if (this.instance.routeName === 'modules') {
          // @ts-ignore
      bus().$emit('active');
        }
      } else {
        item.active = Number(!item.active);
        // @ts-ignore
        this.instance.updateItem(item.id, item).then((response) => {
          if (!response.success) {
            throw new Error(response.error.message)
          }
          showToaster('success', translatedDisplayName(item), translate('successfully-updated'));
        }).catch((error: any) => {
          showToaster('danger', '', error);
        });
        if (this.instance.routeName === 'modules') {
          // @ts-ignore
      bus().$emit('active');
        }
      }

    } else {
      item.active = Number(!item.active);
      // @ts-ignore
      this.instance.updateItem(item.id, { id: item.id, name: item.name, active: item.active })
        .then((response) => {
          if (!response.success) {
            throw new Error(response.error.message)
          }
          showToaster('success', translatedDisplayName(item), translate('successfully-updated'));
          if (this.instance.routeName === 'modules') {
            // @ts-ignore
        bus().$emit('active');
          }
        }).catch((error: any) => {
          showToaster('danger', '', error);
        });

    }

  }

  /* removes or appends all items ids on current page to selectedArray */
  checkAll() {
    if (!this.allChecked) {
      const notCheckedItems = this.localItems.filter((item: IItem) => !this.selectedIds.includes(item.id));
      this.selectedArray = this.selectedArray.concat(notCheckedItems);
      this.allChecked = true;
    } else {
      this.selectedArray = this.selectedArray.filter((item: IItem) => !this.itemIds.includes(item.id));
      this.allChecked = false;
    }
  }


  duplicateItem(item: IItem) {
    if (!this.instance.duplicateItem) {
      throw new Error('duplicateItem method is not defined in instance');
    }
    this.instance.duplicateItem(item.id)
      .then((response: any) => {
        this.$emit('refresh');
        this.editItem(response.data);
      })
      .catch((error) => {
        showToaster('danger', '', error);
      })
  }


  /* when item is checked */
  @Watch('items')
  updateCheck() {
    this.allChecked = this.itemIds.every((id: number) => this.selectedIds.includes(id));
  }

  @Watch('resetSelected')
  clearSelected() {
    this.selectedArray = [];
    this.selectedItem = null;
    this.allChecked = false;
  }

  @Watch('defaultSelectedArray', {immediate: true, deep: true})
  setDefaultSelectedArray() {
    this.selectedArray = this.defaultSelectedArray;
  }

  @Watch('selectedArray')
  emitSelectedArray() {
    this.$emit('input', this.selectedArray);
  }

  @Watch('selectedItem')
  emitSelectedItem() {
    this.$emit('input', this.selectedItem);
  }

  getTranslatedDisplayName(item: IItem) {
    return translatedDisplayName(item);
  }

  get itemIds() {
    return this.localItems.map((item: IItem) => item.id);
  }

  get selectedIds() {
    return this.selectedArray.map((item: IItem) => item.id);
  }

  get noImageSrc() {
    const noImageSetting = stores.Setting.models.find((setting: ISetting) => setting.key === 'no_image_icon');
    if (noImageSetting && noImageSetting.value) {
      return noImageSetting.value;
    }
    return require('@/assets/images/nevron_logo.png');
  }

  formatDate(date: any) {
    return date ? moment(date).format('DD-MM-YYYY') : '';
  }

  formatDateAndTime(date: any) {
    return date ? moment(date).format('DD.MM.YYYY HH:mm') : '';
  }

  showButton(item: object, button: object) {
    // @ts-ignore
    return this.instance.showButtons(item, button)
  }

  handleButtonClick(item:object, button: any) {
    // @ts-ignore
    this.$emit(button.clickEvent, item);
  }

  showIcon(item: object, icon: object) {
    // @ts-ignore
    return this.instance.showIcons(item, icon)
  }

  pivotFieldValue(item: INevronTableGenericItem,field : INevronTableGenericPivotField)
  {
    let itemValue = '';
    if(item[field.pivotKey] && item[field.pivotKey][field.name])
    {
        itemValue = item[field.pivotKey][field.name];
    }else{
        itemValue = item[field.backupFieldName];
    }
    if(field.notTranslatable === false)
    {
      itemValue = Vue.prototype.translate(itemValue)
    }
    return itemValue;
  }
  switchTheme(field : any,item: INevronTableGenericItem)
  {
    let defaultTheme =  'info';
    const value = (item[field.pivotKey] ? item[field.pivotKey][field.name] : item[field.backupFieldName]) === 1
    if(field.switchTheme)
    {
      defaultTheme = field.switchTheme
    }
    if(!value && field.switchInActiveTheme)
    {
      defaultTheme = field.switchInActiveTheme
    }
    if(value && field.switchActiveTheme)
    {
      defaultTheme = field.switchActiveTheme
    }
    return defaultTheme;
  }
}
